<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="
          getExamActivityStatus.PracticalVivaThesisMarkSubmissionCompleted ==
            false
        "
        color="primary"
        v-on="on"
      >
        Marks Entry / Update
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Practical Marks</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="red" icon dark @click="closeDail()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div style="padding-top:30px; padding-left:50px; padding-right:50px;">
        <span class>
          <div class="display-1 mb-4">
            <v-icon size="50" color="primary">dashboard</v-icon>
            <span class="ml-2">Practical Marks Entry/Update</span>
          </div>
        </span>
        <v-card class="px-5" color="#FAFAFA" style="margin-bottom:50px;">
          <v-layout row wrap align-center class="py-3">
            <v-autocomplete
              class="ma-2"
              v-model="searchParam.paper_code"
              :items="subWisePepList"
              :item-text="(item) => item.paper_code + ' - ' + item.paper_name"
              item-value="paper_code"
              label="Select Course"
              persistent-hint
              prepend-icon="library_books"
              :loading="loading"
            >
              <template v-slot:selection="data">
                <v-chip
                  color="#EBEBEB"
                  close
                  @input="searchParam.paper_code = ''"
                  :selected="data.selected"
                  class="chip--select-multi"
                  >{{
                    data.item.paper_code + " - " + data.item.paper_name
                  }}</v-chip
                >
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item"></v-list-tile-content>
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <v-list-tile-title
                      v-html="
                        data.item.paper_code + ' - ' + data.item.paper_name
                      "
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
            <v-btn color="primary" small @click="getCandiData()">
              <v-icon center dark>list</v-icon> &nbsp; Load Student
            </v-btn>
            <v-btn color="primary" small @click="PVstudentList()">
              <v-icon center dark>download</v-icon> &nbspStudent List
            </v-btn>
            <v-btn color="primary" small @click="blankMarkSheet()">
              <v-icon center dark>file_copy</v-icon> &nbspBlank Mark Input List
            </v-btn>
          </v-layout>
        </v-card>

        <v-card color="white">
          <v-layout row>
            <v-card flat>
              <v-badge left overlap>
                <template v-slot:badge>
                  <v-btn
                    round
                    color="primary"
                    fab
                    small
                    style="margin-top: -1em; font-size: 1.3em"
                    class="elevation-0"
                    >{{
                      pagination.totalDataItems ? pagination.totalDataItems : 0
                    }}</v-btn
                  >
                </template>
                <v-card-text class="title">Practical Student List</v-card-text>
              </v-badge>
            </v-card>
            <v-divider class="my-2" vertical></v-divider>
          </v-layout>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="getPvCandidateDataSet.candidates"
          :loading="loading"
          class="elevation-4 mytable"
          hide-actions
        >
          <template v-slot:items="props">
            <td class="text-xs">{{ props.index + 1 }}</td>
            <td class="text-xs-center">{{ props.item.exam_roll_no }}</td>
            <td class="text-xs-center">{{ props.item.reg_no }}</td>
            <td class="text-xs-center">{{ props.item.ac_session }}</td>

            <td class="text-xs-left">
              {{ props.item.name }}
            </td>
            <td class="text-xs-left my--style" width="250">
              <v-text-field
                v-model="props.item.mark"
                label="Input Mark"
                single-line
                autofocus
                hide-details
                class="mb-3"
                :ref="'text' + props.index"
                @click="up(props.item)"
                @keyup.enter="callRef(props.index + 1)"
                @keyup.up="callRef(props.index - 1)"
                @keyup.down="callRef(props.index + 1)"
                :error="getErrorStatus(props.item)"
                :prepend-icon="getIconForMarkEntry(props.item)"
              ></v-text-field>
            </td>
            <td class="text-xs-center">
              {{ props.item.practical_full_marks }}
            </td>
          </template>
        </v-data-table>
      </div>
      <v-flex xs12 mt-4>
        <v-layout align-center justify-center row fill-height>
          <v-btn large round color="primary" @click="submit()">
            Submit
            <v-icon right dark>send</v-icon>
          </v-btn>
        </v-layout>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      previlegedExam: null,
      searchParam: {},
      dialog: false,
      pagination: {
        page: 1,
        rowsPerPage: 50,
        rowsPerPageItems: [50],
      },
      getPracticalWantListByStd: [],
      headers: [
        {
          text: "Sl",
          align: "left",
          value: "examinee_name",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "Exam Roll",
          align: "center",
          value: "examinee_name",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "Registration No.",
          align: "center",
          value: "reg_no",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "Session",
          align: "center",
          value: "reg_no",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "Examinee Name",
          align: "center",
          value: "examinee_name",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "Practical/Field work Mark",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: "Full Marks",
          align: "center",
          value: "id",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    pagination() {
      this.getCandiData();
    },
  },
  computed: {
    subWisePepList() {
      return this.$store.getters.getPvPepData;
    },
    profile() {
      return this.$store.getters.getCollegeUserProfile;
    },
    getPvCandidateDataSet() {
      return this.$store.getters.getPvCandidateData;
    },
    getExamActivityStatus() {
      return this.$store.getters.getExamActivityStatus;
    },
  },
  created() {
    this.getExamPrivData();
    this.getSubjectWisePepData();
    this.$store
      .dispatch("getExamActivityStatus", {
        exam_code: this.previlegedExam.exam_master.exam_code,
        exam_year: this.previlegedExam.exam_master.exam_year,
      })
      .then((data) => {
        console.log(data);
      });
  },
  mounted() {
    return this.up();
  },
  methods: {
    getExamPrivData() {
      this.previlegedExam = JSON.parse(
        localStorage.getItem("current-privilege")
      );
    },

    getSubjectWisePepData() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_type = "2";
      console.log("Tanvir! This is peram data", peram);
      this.$store.dispatch("fetchPvPepData", peram);
    },
    getCandiData() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.exam_year = this.previlegedExam.exam_master.exam_year;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_code = this.searchParam.paper_code;
      peram.college_code = this.profile.college_code;
      console.log("Tanvir! This is candi peram data", peram);
      if (peram.paper_code) {
        this.$store.dispatch("fetchPvCandidateData", peram).then((data) => {
          this.loading = false;
          this.sl_count = data.from;
          console.log("Tanvir, This is total", data.total_candidate);
          this.pagination.totalDataItems = data.total_candidate;
        });
      }
    },
    getErrorStatus(item) {
      console.log("You are Beautiful", item);
      let flg1 = this.isPositiveInteger(item.mark);
      let flg2 = parseInt(item.mark) <= parseInt(item.practical_full_marks);
      if (item.mark == "A") return false;
      if (flg1 & flg2) return false;
      else return true;
    },
    getIconForMarkEntry(item) {
      let flg1 = this.isPositiveInteger(item.mark);
      let flg2 = parseInt(item.mark) <= parseInt(item.practical_full_marks);
      if (item.mark == "A") return "done";

      if (flg1 & flg2) return "done";
      else return "close";
    },
    isPositiveInteger(n) {
      return n >>> 0 === parseFloat(n);
    },
    blankMarkSheet() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.exam_year = this.previlegedExam.exam_master.exam_year;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_code = this.searchParam.paper_code;
      peram.college_code = this.profile.college_code;
      peram.download_as = "pdf";

      if (peram.paper_code) {
        this.$store.dispatch("PracticalBlankMarkSheet", peram);
      }
    },
    PVstudentList() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.exam_year = this.previlegedExam.exam_master.exam_year;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_code = this.searchParam.paper_code;
      peram.college_code = this.profile.college_code;
      peram.download_as = "pdf";

      if (peram.paper_code) {
        this.$store.dispatch("PracticalVivaStudentList", peram);
      }
    },
    submit() {
      var that = this;
      let data = [];
      this.getPvCandidateDataSet.candidates.forEach((obj) => {
        if (obj.mark != null) {
          if (this.isPositiveInteger(obj.mark) || obj.mark == "A") {
            if (
              parseInt(obj.mark) <= parseInt(obj.practical_full_marks) ||
              obj.mark == "A"
            ) {
              data.push(obj);
            }
          }
        }
      });
      console.log("Tanvir marks obj", data);
      if (data.length < 1) {
        this.$store.commit("SET_SNACKBAR", {
          msg: "No Data To Submit",
          color: "red",
        });
      } else {
        let PVperam = {};
        PVperam.exam_code = this.previlegedExam.exam_master.exam_code;
        PVperam.exam_year = this.previlegedExam.exam_master.exam_year;
        PVperam.type = 2;
        PVperam.final_status = 0;
        PVperam.candidate_marks = data;
        this.$store
          .dispatch("submitPracticalVivaMarks", PVperam)
          .then((data) => {
            // that.close();
            // that.searchVivaExamList();
          });
      }
    },
    closeDail() {
      this.$store.dispatch("clearPvCandidateData");
      this.dialog = false;
    },
  },
};
</script>
<style lang=""></style>
